import { createVersionPolling } from "version-polling";
import { ElMessageBox } from "element-plus";
import { useCool } from "/@/cool";
import { compareVersion, getCurrentVersion, getInterval } from "/@/cool/config/version";

export const versionPolling = () => {
	const { service } = useCool();


	createVersionPolling({
		immediate: false,
		appETagKey: "__APP_ETAG__",
		pollingInterval: 5000,
		// htmlFileUrl: `${location.origin}${location.pathname}?t=${Date.now()}`,
		silent: location.origin === "http://localhost:9000",
		onUpdate: (self) => {
			service.appVersion
				.latest({ app_id: 1, host: `${location.origin}` })
				.then((res) => {
					const cfg = { new_version: res.app_version, old_version: getCurrentVersion() };
					console.log("版本信息", cfg);
					if (!compareVersion(res.app_version)) {
						console.log("版本号过低", cfg);
						return;
					}
					const content = `<h4>版本号：${res.app_version}</h3>${res.update_content}`;
					ElMessageBox.confirm(content, "发现新版本，是否立即更新？", {
						type: "warning",
						dangerouslyUseHTMLString: true,
						cancelButtonText: "暂不更新",
						confirmButtonText: "立即更新"
					})
						.then(() => {
							self.onRefresh();
						})
						.catch(() => {
							setTimeout(() => {
								self.start();
							}, getInterval());
						});
				})
				.catch(() => {
					console.log("获取版本信息错误");
				});
		}
	});
};
